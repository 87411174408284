import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserVerification } from "../api/postAPI";
import AlertComponent from "../components/alert";
import { ReSendVerifyCode } from "../api/postAPI";
import { useLocation } from "react-router-dom";
import Logo from '../assets/MCI.jpg';
import mPathic from '../assets/MPathic.png';

const VerifyUser = () => {
    const location = useLocation();
    const { Id, registerUser } = location.state || {};
    const [userName, setUserName] = useState(registerUser);
    const [verifyCode, setVerifyCode] = useState('');
    const [error, setError] = useState(null);
    const [id, setId] = useState(Id)
    const [timer, setTimer] = useState(30);
    const nav = useNavigate();

    if (!id) {
        setId(sessionStorage.getItem('Id'));
    }

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval();
        }
        return () => clearInterval(interval);
    }, [timer]);

    const verify = async () => {
        if(verifyCode !== ""){
            try {
                const res = await UserVerification(id, userName, verifyCode);
                if (res === "Success") {
                    setError({ code: 0, message: "User verified successfully!!" });
                    nav('/');
                } else {
                    setError({ code: 1, message: res.message + " Failure in verifying the user" });
                }
            } catch (error) {
                console.log(error)
                setError({ code: 1, message: error.response.data });
            }
        } else {
            setError({ code: 1, message: 'Please enter the verify Code sent to the email ID' });
        }
    }

    const handleAlertClose = () => {
        setError(null);
    }

    const reSendVerificationCode = async () => {
        if (!userName) {
            setError({ code: 1, message: "Please enter the username" });
        } else {
            let res = await ReSendVerifyCode(userName);
            if (res === "Success") {
                setTimer(30);
                setError({ code: 0, message: "Verification code has been sent" });
            } else {
                setError({ code: 1, message: "Some Problem occured \nPlease try again" });
            }
        }
    }

    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <div className="card p-6 w-[90%] max-w-[800px]">
                <div className="p-0 m-0 flex justify-center items-center">
                    <img src={Logo} alt="Yale-Logo" className="h-24 w-auto" />
                </div>
                <div className="flex justify-center items-center pb-6 m-0 pt-2">
                    <img src={mPathic} alt="mPathicLogo" className="h-8 w-auto" />
                </div>
                <hr className="border-2 border-blue-900 mb-4" />
                <div className="mx-auto w-full max-w-[400px] pb-3">
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between pt-5 gap-4">
                            <div>
                                <label htmlFor="studentId" className="form-label font-semibold">Student ID</label>
                            </div>
                            <div>
                                <input type="text" placeholder="Stud101" className="input-control flex-grow" value={id} readOnly />
                            </div>
                        </div>
                    </div>

                    <div className="card pr-5 pl-5 pt-5">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="username" className="form-label">Username</label>
                            </div>
                            <div className="w-100">
                                <input type="text" className="input-control flex-grow" placeholder="someone@mail.com" id="username" value={userName} onChange={(e) => setUserName(e.target.value)} required />
                            </div>
                            <div>
                                <label htmlFor="verifyCode" className="form-label">Verification Code</label>
                            </div>
                            <div>
                                <input type="text" className="input-control flex-grow" id="vefiCode" onChange={(e) => setVerifyCode(e.target.value)} required />
                            </div>
                        </div>

                        <div className="flex justify-center items-center pt-5">
                            <button type="submit" className="btn p-2 text-semibold w-[50%]" onClick={verify}>Complete Registration</button>
                        </div>
                        <div className="flex justify-end items-center pt-2">
                            <button
                                type="button"
                                className={`linkButton p-2 text-semibold w-[50%] ${timer > 0 ? "pointer-events-none opacity-50" : ""}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    reSendVerificationCode();
                                }}
                            >
                                Resend verification code ({timer > 0 ? timer : "Go"})
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {error ? (<AlertComponent errorCode={error.code} errorMessage={error.message} onClose={handleAlertClose} />) : null}
        </div>
    )
}

export default VerifyUser