import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import './styles.css'
import YaleHeader from '../assets/mci_logo_text-light80.svg'

const MainLayout = ({ children }) => {
    const location = useLocation();
    const isHelpPage = location.pathname === "/help";
    return (
        <div className="h-screen flex flex-col">
            <nav className="flex justify-between items-center pl-3 pr-3 gradient-element">
                <div className="flex items-center h-16">
                    <img src={YaleHeader} alt="YaleHeader" className="h-8 w-auto pl-4" />
                </div>
                {!isHelpPage && (
                    <div className="flex justify-between">
                        <div className="pl-4 pr-4">
                            <NavLink
                                to="/help"
                                className={({ isActive }) =>
                                    isActive
                                        ? "navItems font-semibold uppercase text-activeLink"
                                        : "navItems font-semibold uppercase"
                                }
                            >
                                Help
                            </NavLink>
                        </div>
                        &nbsp;
                    </div>
                )}
            </nav>
            <div className="flex-grow flex justify-center items-center overflow-hidden">
                <div className="blur-overflow-content w-full h-fit">{children}</div>
            </div>
            <div>
                <hr className="w-full" />
                <div className="footer py-2 flex justify-center items-center" />
            </div>
        </div>
    )
}

export default MainLayout