import React, { useState, useEffect } from "react";
import './pageStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import CreateDropOrChooseFile from '../components/fileuploader';
import { getBundleFiles } from "../api/getAPI";
import useToken from "../hook/useToken";
import { DeleteBundles } from "../api/deleteAPI";
import AlertComponent from "../components/alert";

const EducatorHome = () => {
    const RegistrationUpload = CreateDropOrChooseFile();
    const WinBundleUpload = CreateDropOrChooseFile();
    const MAcBundleUpload = CreateDropOrChooseFile();
    let { token } = useToken();

    const [winUploadedFiles, setWinUploadedFiles] = useState([]);
    const [macUploadedFiles, setMacUploadedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [pageReload, setPageReload] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let resWin = await getBundleFiles('Demo', 'Win', token);
                setWinUploadedFiles(resWin);
                let resMac = await getBundleFiles('Demo', 'Mac', token);
                setMacUploadedFiles(resMac);
            } catch (err) {
                console.log(err);
                setError({ code: 1, message: 'Unable to get the bundle files ' + err })
            }
        }

        fetchData()
    }, [token])

    const deleteFiles = async (folderName, bundleName) => {
        try {
            let res = await DeleteBundles(folderName, bundleName, token);
            if (res) {
                setError({ code: 0, message: 'File ' + bundleName + ' has been deleted from ' + folderName });
            }
        } catch (err) {
            console.log(err)
            setError({ code: 1, message: 'Unable to delete the file ' + err });
        } finally {
            setPageReload(true);
        }
    }

    const handleAlertClose = () => {
        setError(null);
        if(pageReload) {
            window.location.reload();
        }
    };

    return (
        <div className="flex flex-col justify-between">
            <div className="bg-subHeader p-3">
                <p className="text-center font-bold text-white">-- Super User MCI --</p>
            </div>
            <div className="p-6">
                <div className="flex justify-center m-5 p-5 h-[40%]">
                    <RegistrationUpload title="Upload student registration file" operation="Register_Upload" folderName='' BundleName='' />
                </div>
                <div className="flex justify-center h-[40%] mb-4">
                    <div className="card w-[70%] bg-cardSub4 p-6">
                        <p className="text-center mb-4 text-xl font-semibold p-3">Windows Scenario Bundles</p>
                        <div className="flex justify-around">
                            <WinBundleUpload title="Upload Scenario Bundles" operation="Bundle_Upload" folderName="Win" BundleName="Windows1" />
                            <div className="card h-[20%] p-8 flex flex-col justify-center items-center border-dashed border-2 border-sky-500 bg-cardSub2 w-[40%]">
                                <p className="mb-2">Uploaded Files</p>
                                <div className="flex">
                                    <ul style={{ listStyle: 'none' }}>
                                        {winUploadedFiles.map((file, index) => (
                                            <li key={index}>
                                                <div className="flex justify-between items-center">
                                                    <FontAwesomeIcon icon={faImage} />
                                                    <p className="pl-5 pr-5 text-left">{file?.name}</p>
                                                    <FontAwesomeIcon icon={faTrashCan} onClick={() => {
                                                        deleteFiles('Win', file.name)
                                                    }} />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>&nbsp;
                <div className="flex justify-center h-[40%] mb-4">
                    <div className="card w-[70%] bg-cardSub4 p-6">
                        <p className="text-center mb-4 text-xl font-semibold p-3">MAC Scenario Bundles</p>
                        <div className="flex justify-around">
                            <MAcBundleUpload title="Upload Scenario Bundles" operation="Bundle_Upload" folderName="Mac" BundleName="MAC" />
                            <div className="card h-[20%] p-8 flex flex-col justify-center items-center border-dashed border-2 border-sky-500 bg-cardSub2 w-[40%]">
                                <p className="mb-2">Uploaded Files</p>
                                <div className="flex">
                                    <ul style={{ listStyle: 'none' }}>
                                        {macUploadedFiles.map((file, index) => (
                                            <li key={index}>
                                                <div className="flex justify-between items-center">
                                                    <FontAwesomeIcon icon={faImage} />
                                                    <p className="pl-5 pr-5 text-left">{file?.name}</p>
                                                    <FontAwesomeIcon icon={faTrashCan} onClick={() => {
                                                        deleteFiles('Mac', file.name)
                                                    }} />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {error ? (
                    <AlertComponent
                        errorCode={error.code}
                        errorMessage={error.message}
                        onClose={handleAlertClose}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default EducatorHome