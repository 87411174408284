import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useToken from "../hook/useToken";
import AlertComponent from "../components/alert";
import Logo from '../assets/MCI.jpg';
import mPathic from '../assets/MPathic.png';
import { ResetPassword, VerifyReset } from "../api/putAPI";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import './pageStyles.css';

const ResetPasswordPage = () => {
    const location = useLocation();
    const [userName, setUserName] = useState(location.state?.userData || '');
    const [buttonName, setButtonName] = useState('Proceed');
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [error, setError] = useState(null);
    const [passwd, setPasswd] = useState('');
    const [verifyCode, setVerifyCode] = useState('');
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
    const isUserNameFromLocation = !!location.state?.userData;
    const nav = useNavigate();
    let { token } = useToken();
    const isForgotPasswordPage = location.pathname === '/forgotPassword';

    const proceedButtonClicked = async () => {
        if (buttonName === "Proceed") {
            if (userName !== "") {
                try {
                    let res;
                    if (isForgotPasswordPage) {
                        res = await ResetPassword(userName)
                    } else {
                        res = await ResetPassword(userName, token);
                    }
                    if (res) {
                        setError({ code: 0, message: res.message });
                        setButtonName("Reset Password");
                        setShowCodeInput(true);
                    }
                } catch (err) {
                    console.log(err)
                    setError({ code: 1, message: err.response.data.message })
                }
            } else {
                setError({ code: 1, message: 'Please enter username' });
            }
        } else {
            if (userName !== "" && passwd !== "" && verifyCode !== "") {
                try {
                    let res = await VerifyReset(userName, passwd, verifyCode, token);
                    setError({ code: 0, message: res.message });
                    setShouldNavigate(true)
                } catch (err) {
                    console.log(err);
                    setError({ code: 1, message: err.response.message });
                    setPasswd('');
                    setVerifyCode('');
                }
            } else {
                setError({ code: 1, message: 'Please enter the password and verification code that has been sent to your mail' });
            }
        }
    }

    const handleCloseAlert = () => {
        setError(null);
        if (shouldNavigate) {
            if (isForgotPasswordPage) {
                nav('/');
            } else {
                nav('/home');
            }
            setShouldNavigate(false);
        }
    };

    const handlePasswordBlur = () => {
        setShowPasswordChecklist(false);
    }

    return (
        <div className="h-screen flex flex-col justify-center items-center">
            <div className="card p-6 w-[90%] max-w-[800px]">
                <div className="p-0 m-0 flex justify-center items-center">
                    <img src={Logo} alt="Yale-Logo" className="h-24 w-auto" />
                </div>
                <div className="flex justify-center items-center pb-6 m-0 pt-2">
                    <img src={mPathic} alt="mPathicLogo" className="h-8 w-auto" />
                </div>
                <hr className="border-2 border-blue-900 mb-4" />
                <div className="mx-auto w-full max-w-[500px]">
                    <p className="text-center">Reset Password</p>
                    <div className="grid grid-cols-2 gap-4 pt-5">
                        <div>
                            <label htmlFor="username" className="form-label font-semibold">User Email</label>
                        </div>
                        <div>
                            {isUserNameFromLocation ? (
                                <input
                                    type="text"
                                    className="input-control w-full"
                                    id="username"
                                    value={userName}
                                    readOnly
                                    required
                                />
                            ) : (
                                <input
                                    type="text"
                                    className="input-control w-full"
                                    id="username"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    required
                                />
                            )}
                        </div>
                    </div>
                    {showCodeInput ? (
                        <div className="grid grid-cols-2 gap-4 pt-5">
                            <div>
                                <label htmlFor="inputPassword4" className="form-label font-semibold">New Password</label>
                            </div>
                            <div className="relative flex items-center">
                                <input type="password" className="input-control w-full" id="inputPassword4" placeholder="New Password" onChange={(e) => setPasswd(e.target.value)} onFocus={() => setShowPasswordChecklist(true)} onBlur={handlePasswordBlur} required />
                                <div className={`bg-white p-4 rounded-md shadow-md w-[100%] absolute right-0 left-[110%] top-0 z-10 sm:w-[200%] sm:left-[100%] ${!showPasswordChecklist ? 'hidden' : ''}`}>
                                    <div className="relative">
                                        <div className="absolute top-4 left-[-12px] w-0 h-0 border-t-[8px] border-t-transparent border-r-[12px] border-r-white border-b-[8px] border-b-transparent"></div>
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital", "lowercase", "maxLength"]}
                                            minLength={8}
                                            maxLength={20}
                                            value={passwd}
                                            specialCharsRegex={/[~`¿¡!#$%\\^&*€£@+÷=\-[\]\\;,/{}\\(\\)|\\:<>\\?\\.\\_]/g}
                                            className="text-xs overflow-y-scroll"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="codeInput" className="form-label font-semibold">Verification Code</label>
                            </div>
                            <div>
                                <input type="text" id="condeInput" placeholder="verification code" className="input-control w-full" onChange={(e) => { setVerifyCode(e.target.value) }} />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex justify-center items-center pt-4">
                        <button type="submit" className="loginBtn p-2 font-semibold w-[50%] text-white" onClick={proceedButtonClicked}>{buttonName}</button>
                    </div>
                </div>
            </div>
            {error && <AlertComponent errorCode={error.code} errorMessage={error.message} onClose={handleCloseAlert} />}
        </div>
    )
}

export default ResetPasswordPage
