import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";
import { RoleContext } from "../utils/roleContext";
import useToken from "../hook/useToken";
import { useNavigate } from "react-router-dom";
import YaleLogo from '../assets/mci_logo_text-light80.svg';

const CommonLayout = ({ children }) => {
    const { role } = useContext(RoleContext);
    const [showDropdown, setShowDropdown] = useState(false);
    let userName = sessionStorage.getItem("userName");
    const { clearToken } = useToken();
    let nav = useNavigate();
    const location = useLocation();
    const isInstructionsPage = location.pathname === "/instructions";
    let headerText;

    switch (role) {
        case "superUser":
        case "educatorAdmin":
        case "educator":
        case "user":
            headerText = "Patient Communication & Implicit Bias Mitigation Training";
            break;
        default:
            headerText = "MPathic-IBCH";
            break;
    }

    const userLogout = () => {
        setShowDropdown(false);
        clearToken();
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('Id');
        localStorage.clear();
        nav('/');
    }

    const resetPassword = async () => {
        setShowDropdown(false);
        nav('/resetPasswrd', { state: { userData: userName } })
    }

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            <nav className="flex justify-between items-center pl-3 pr-3 pb-2 gradient-element">
                <div className="flex items-center h-16">
                    {headerText === 'MPathic-IBCH' ? (
                        <img src={YaleLogo} alt="YaleLogo" className="h-8 w-auto pl-4" />
                    ) : (
                        <h1 className="text-headerText font-semibold text-xl">{headerText}</h1>
                    )}
                </div>
                <div className="flex justify-between items-center p-3">
                    <div className="pl-4 pr-4">
                    {role === "Student" && (
                            isInstructionsPage ? (
                                <Link to="/home" className="navItems font-semibold uppercase">
                                    Dashboard
                                </Link>
                            ) : (
                                <Link to="/instructions" className="navItems font-semibold uppercase">
                                    Instructions
                                </Link>
                            )
                        )}
                    </div>{" "}
                    &nbsp;
                    <div className="flex flex-col justify-between items-center">
                        <div className="cursor-pointer" onClick={() => setShowDropdown(!showDropdown)}>
                            <p className="text-whiteFg text-lg font-semibold block px-4 py-2 hover:bg-gray-100 truncate">{(() => {
                                const role = sessionStorage.getItem("role");
                                if (role === "Student") {
                                    return "Student Id: ";
                                } else {
                                    return "User Id: ";
                                }
                            })()}
                                {sessionStorage.getItem("userId")}</p>
                        </div>
                        {showDropdown && (
                            <div className="absolute top-20 right-0 bg-white shadow-lg rounded-md w-48 z-10 py-2 outline outline-offset-2 outline-1">
                                <div className="grid grid-cols-1 divide-y">
                                    <div>
                                        <button
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                            onClick={resetPassword}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                            onClick={userLogout}
                                        >
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
            <div className="flex-grow overflow-y-auto">
                <div className="mb-3">{children}</div>
            </div>
            <div>
                <hr className="w-full" />
                <div className="footer py-2 flex justify-center items-center" />
            </div>
        </div>
    );
};

export default CommonLayout;