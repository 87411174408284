import React, { useEffect, useState } from "react";
import { StudentProgressUpdata } from "../api/putAPI";
import useToken from "../hook/useToken";

const StudentsViewPage = ({ studentList, maxLength }) => {
    const [studentData, setStudentData] = useState([]);
    let { token } = useToken();
    useEffect(() => {
        setStudentData(studentList)
    }, [studentList])
    
    const handleCheckboxChange = (student, field, value) => {
        const updatedStudents = studentData.map(s => {
            if (s.studentId === student.studentId) {
                return { ...s, [field]: value };
            }
            return s;
        });
    
        setStudentData(updatedStudents);
        const updatedStudent = updatedStudents.find(s => s.studentId === student.studentId);
        if (updatedStudent) {
            sendData(updatedStudent);
        }
    };

    const sendData = async (data) => {
        try {
            let res = await StudentProgressUpdata('Demo', sessionStorage.getItem("userId"), data, token);
            console.log(res)
        } catch(err) {
            console.log(err)
        }
    }

    return (
        <div>
            <table className="w-[50%] divide-y divide-gray1-200 border-2">
                <thead className="bg-cardMain w-[100%]">
                    <tr className="w-[50%]">
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2"
                            colSpan="3"
                        >
                            Student List
                        </th>
                        <th
                            scope="col"
                            className={`px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 w-${maxLength}`}
                            colSpan="3"
                        >
                            User
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            First Patient Encounter
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2'
                            colSpan="1"
                        >
                            IAT
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Monique
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Reflection
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Reggie
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Reflection
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Tyrone
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Reflection
                        </th>
                        <th
                            scope="col"
                            className='px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider border-2 '
                            colSpan="1"
                        >
                            Second Patient Encounter
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {studentData.map((student, index) => (
                        <tr key={index}>
                            <td className="border-r border-l border-gray-200">
                                <div className="flex flex-col justify-center items-center">
                                    <p className="px-6 py-3 text-sm tracking-wider">Enabled</p>
                                    <div
                                        className={`w-5 h-5 rounded-md ${student.hasCompletedRegistration
                                            ? 'bg-gray1-400'
                                            : 'bg-white border border-gray1-300'
                                            }`}
                                    ></div>
                                </div>
                            </td>
                            <td className="border-r border-gray-200">
                                <div className="px-6 py-4 whitespace-nowrap">
                                    <p>{student.studentId}</p>
                                </div>
                            </td>
                            <td colSpan={4} className="border-r border-gray-200">
                                <div className="px-6 py-4 whitespace-nowrap">
                                    <p>{student.userName}</p>
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['firstEncounter']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "firstEncounter",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student.iatTaken} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "iatTaken",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['scenario1']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "scenario1",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['reflection1']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "reflection1",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['scenario2']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "scenario2",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['reflection2']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "reflection2",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['scenario3']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "scenario3",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['reflection3']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "reflection3",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                            <td>
                                <div className="flex justify-center items-center">
                                    <input type="checkbox" checked={student['secondEncounter']} onChange={(e) =>
                                        handleCheckboxChange(
                                            student,
                                            "secondEncounter",
                                            e.target.checked
                                        )
                                    } />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default StudentsViewPage
